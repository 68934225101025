.blog-tax-widget {
  .widget {
    h3 {
      border-bottom: 2px solid $brand-navy;
      color: $brand-navy;
    }

    ul {
      color: $brand-red;
      list-style-type: none;
      padding-left: 0;

      li {
        font-size: 1.2rem;
      }
    }
  }
}