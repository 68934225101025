/* HOMEPAGE
   ========================================================================== */
section#front-page {
  section#page-banner {
    div#carousel-page {
    	div.carousel-inner {
    		position: relative;

    		div.single-banner {
    			height: 70vh;
    			background-size: cover;
				  background-repeat: no-repeat;
					background-position: center center;

					div.single-banner-inner {
						height: 70vh;

						div.banner-resize {
							height: 70vh;

							div.row {
								height: 70vh;
								position: relative;

								div.col-sm-6 {
									padding-left: 0px;

									p {
										background-color: rgba(0,0,0,.5);
										color: $white;
										text-align: center;
										padding: 10px;
										vertical-align: middle;
									}
								}
							}
						}
					}
    		}

    		.super-family {
					img {
						vertical-align: middle;
				    position: absolute;
				    bottom: 0px;
				    width: 100%;
				    left: 100%
					}
				}
    	}
    }
  }

  section#cloud-divide-top {
		.col-sm-12 {
			padding-left: 0px;
			padding-right: 0px;

  		img {
  			width: 100%;
  			position: relative;

	    	@include media-breakpoint-up(xs) { 
          top: -30px;
        }

        @include media-breakpoint-up(sm) { 
          top: -44px;
        }

        @include media-breakpoint-up(md) { 
          top: -61px;
        }

        @include media-breakpoint-up(lg) { 
          top: -79px;
        }

        @include media-breakpoint-up(xl) { 
          top: -93px;
        }
	    }
	  }
  }

  section#introduction {
   	padding-bottom: 50px;

		.blue {
			color: $brand-navy;
			text-align: center;

			h1 {
				font-family: 'Cabin-Bold';
			}

			img {
				width: 35%;
			}
		}

		.red {
			color: $brand-red;
			text-align: center;

			h1 {
				font-family: 'Cabin-Bold';
			}

			img {
				width: 35%;
			}
		}

		.yellow {
			color: $brand-yellow;
			text-align: center;

			h1 {
				font-family: 'Cabin-Bold';
			}

			img {
				width: 35%;
			}
		}
	}
}

section#cloud-divide-bottom {
  .col-sm-12 {
  	padding-left: 0px;
  	padding-right: 0px;
  	z-index: 1000;

  	img {
  		background: -moz-linear-gradient(top,  #adcfe1 0%, #fff 100%); /* FF3.6-15 */
  		background: -webkit-linear-gradient(top,  #adcfe1 0%,#fff 100%); /* Chrome10-25,Safari5.1-6 */
  		background: linear-gradient(to bottom,  #adcfe1 0%,#fff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a4748', endColorstr='#656263',GradientType=0 ); /* IE6-9 */
  		width: 100%;
  		position: relative;

  		@include media-breakpoint-up(xs) { 
        top: 17px;
      }

      @include media-breakpoint-up(sm) { 
        top: 37px;
      }

      @include media-breakpoint-up(md) {
        top: -20px;
      }

      @include media-breakpoint-up(lg) { 
        top: -13px;
      }
  	}
  }
}

section#about {
	.col-md-5 {
		img {
			width: 100%;
			height: auto;
			z-index: 1000;

			@include media-breakpoint-up(md) { 
        top: -66px;
        position: relative;
      }
		}
	}
	.col-md-7 {
		@include media-breakpoint-up(lg) { 
      padding-top: 50px;
    }

		h4 {
			color: $brand-red;
			font-family: 'Cabin-Regular';

			span.large-text {
				font-size: 2em;
			}
		}
		h5 {
			color: $brand-red;
			font-family: 'Cabin-SemiBold';
			text-align: center;
			a {
				color: $brand-red;
			}
		}
	}
}

section#featured-videos {
  .video-section-header h1 {
    color: $brand-orange;
    font-family: 'Cabin-SemiBold';
  }

  .video-section-description h4 {
    color: $brand-orange;
    font-family: 'Cabin-Regular';
    padding-bottom: 30px;
  }

  .homepage-video-content {
    padding-bottom: 15px;
    padding-top: 15px;

    .embed-container { 
      position: relative; 
      padding-bottom: 56.25%;
      overflow: hidden;
      max-width: 100%;
      height: auto;
    } 

    .embed-container iframe,
    .embed-container object,
    .embed-container embed { 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .video-text {
      color: $brand-orange;
      font-family: 'Cabin-Regular';
      padding-top: 20px;
    }
  }

  .video-section-more {
    padding-top: 20px;
    h4 a {
      color: $brand-orange;
    }
  }
}

section#goal-events {
	padding: 50px 0px;

	.section {
		border: 1px solid $light-gray;
		border-radius: 25px;
		padding: 20px;
	}

	h1 {
		color: $brand-navy;
		font-family: 'Cabin-SemiBold';
	}

	h4.content {
		color: $brand-navy;
		font-family: 'Cabin-Regular';
	}

	.donate-playground {
		border-top: 1px solid $gray-lighter;

		h4.title {
			color: $brand-navy;
			font-family: 'Cabin-SemiBold';
			padding-top: 20px;
		}

		h5 {
			color: $brand-navy;
			font-family: 'Cabin-Regular';
		}

		h6 {
			color: $brand-navy;
			font-family: 'Cabin-SemiBold';
		}

		a.learn-more {
			color: $brand-navy;
			font-family: 'Cabin-SemiBold';
		}

		.vcenter {
			display: flex;
		  align-items: center;

			.col-sm-9 {
 			  .progress {
     			background-color: $brand-yellow;
     			border: 2px solid $brand-orange;
     			padding: 3px;

     			.progress-bar {
     				background-color: $brand-red;
     			}
   		  }
   	  }

     	.col-sm-3 {
     		img {
     			width: 100%;
     		}
     	}
    }
  }

  .event {
    .section {
	    .list-events {
		    border-top: 1px solid $gray-lighter;
		    padding-top: 10px;

        .event-thumb {
          img {
            height: auto;
            width: 100%;
          }
        }

        h5 {
          color: $brand-red;
        }
    	}

    	.vcenter {
   			display: flex;
  		  align-items: center;
   		}

   		.event-content {
   			color: $brand-navy;
   			font-family: 'Cabin-Regular';
   			font-size: 20px;
   			padding-bottom: 10px;
   			text-align: left;
   			line-height: 1.2em;

   			a {
   				color: $brand-navy;
   				font-family: 'Cabin-SemiBold';
   				font-size: 20px;
   				text-align: left;
   			}
   		}
 	  }
	}
}

section#become-a-hero {
	background-color: $brand-yellow;

	.col-sm-4 {
		padding-left: 0px;
	}

	.content {
		@include media-breakpoint-up(lg) {
      padding-top: 55px;
    }

		h1 {
			color: $brand-red;
		}

		h4 {
			color: $brand-red;
			font-family: 'Cabin-Regular';
		}

		h5 {
			color: $brand-red;
			font-family: 'Cabin-SemiBold';
			font-weight: bold;
		}

    a {
      color: $brand-red;
    }
	}
}

/* PAGE LAYOUT
   ========================================================================== */
.background {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 430px;
	position: relative;

	.banner-text {
		position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    h1 {
    	color: $white;
    	text-shadow: 2px 2px 8px #000;
    }
	}
}

.home-text {
	.home-header {
		font-size: 60px;
		padding: 50px 0px 0px 0px;
	}

	.home-content {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 3rem;
		padding: 15px;
	}
}

.home-content {
	margin-bottom: 3rem;
}

/* SERVICES TEMPLATE
   ========================================================================== */
#service {
	.background {
		position: relative;
		height: 430px;

		.service-header {
			position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      color: white;

      .page-header {
      	h1 {
      		text-shadow: 2px 2px 8px #000;
      	}
      }
		}
	}

	.service-content {
		padding-bottom: 45px;

		.col-sm-6 {
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

   /* Contact LAYOUT
   ========================================================================== */
#contact-page {
  .background {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 430px;
		position: relative;

		.contact-header {
			position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-right: -50%;
	    transform: translate(-50%, -50%);

	    h1 {
	    	color: $white;
	    	text-shadow: 2px 2px 8px #000;
	    }
		}
	}

	.content {
		padding-bottom: 50px;
	}

	.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input[type=submit] {
		background-color: $brand-primary;
		border: none;
		color: $white;
		width: 100%;
	}
}

 /* Meet Our Team Template LAYOUT
   ========================================================================== */
section#team-members {
 	.background {
  	background-position: center center;
  	background-repeat: no-repeat;
  	background-size: cover;
  	height: 430px;
  	position: relative;

  	.team-header {
  		position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-right: -50%;
	    transform: translate(-50%, -50%);

	    h1 {
	    	color: $white;
	    	text-shadow: 2px 2px 8px #000;
	    }
  	}
	}
  
  .container {
	  .team-content {
	   	.team-member {
	   		padding-bottom: 40px;

	   		img {
			    height: auto;
			    position: absolute;
			    top: 50%;
			    transform: translate(0, -50%);
			    vertical-align: middle;
			    width: 100%;
	   		}

	   		.entry-content {
	   			a.btn {
	   				background-color: $brand-red;
	   				border: none;
	   			}
	   		}
	   	}
   	}

   	.board {
   		padding-bottom: 40px;
   		padding-left: 20px;
   		padding-top: 40px;

   		.widget {
   			h3 {
   				text-align: center;
   			}

	   		.textwidget {
	   			text-align: center;

	   			h1, h2, h3, h4, h5, h6, p {
	   				text-align: center;
	   			}
	   		}
	   	}
   	}
	}
}


/* Cloud header 
   ========================================================================== */
.cloud-top {
	img {
		position: relative;
		width: 100%;
		z-index: 1000;

		@include media-breakpoint-up(xs) {
			top: -29px;
		}

		@include media-breakpoint-up(sm) {
			top: -45px;
		}

		@include media-breakpoint-up(md) {
			top: -62px;
		}

		@include media-breakpoint-up(lg) {
			top: -79px;
		}

		@include media-breakpoint-up(xl) {
			top: -96px;
		}
	}
}

.cloud-bottom {
	img {
		position: relative;
		width: 100%;
		background: -moz-linear-gradient(top,  #adcfe1 0%, #fff 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #adcfe1 0%,#fff 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #adcfe1 0%,#fff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4a4748', endColorstr='#656263',GradientType=0 ); /* IE6-9 */

		@include media-breakpoint-up(xs) {
			top: 0px
		}

		@include media-breakpoint-up(xl) {
			top: -60px;
		}
	}
}

/* Posts Page 
   ========================================================================== */

#post {	
	.background {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 430px;
		position: relative;

		.archive-header {
      left: 50%;
      margin-right: -50%;
			position: absolute;
	    top: 50%;
	    transform: translate(-50%, -50%);

	    h1 {
	    	color: $white;
	    	text-shadow: 2px 2px 8px #000;
	    }
		}
	}

	.post-content {
		img {
			width: 100%;
			height: auto;
		}
    
		a {
			color: $brand-red;
		}
	}
}

/* Single Post 
   ========================================================================== */
#post-single {	
	.banner-background {
		background-image: url(../images/Jakes_FOD_Playground.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 430px;
		position: relative;

		.header {
			position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-right: -50%;
	    transform: translate(-50%, -50%);

	    h1 {
	    	color: $white;
	    	text-shadow: 2px 2px 8px #000;
	    }
		}
	}

	.post-content {
		a {
			color: $brand-red;
		}
	}
}

  /* Tribe Events
   ========================================================================== */
#tribe-events {
  #tribe-events-content {
    h1.tribe-events-single-event-title {
      color: $brand-navy;
      font-family: 'Cabin-Bold';
    }

    .tribe-events-schedule {
      h2 {
        color: $brand-navy;
        font-family: 'Cabin-Regular';
      }
    }
  }
}

  /* 404
   ========================================================================== */
.error404 .container {
  margin-top: 10rem;
}
