footer.content-info {
	background-color: $brand-navy;

	a.footer-logo {
		img {
			height: 180px;
			width: auto;
		}
	}

	.footer-social {
		ul.social-links {
			margin-bottom: 0;
      padding: 15px;

      li.social-intro {
      	color: $white;
      	font-size: 1rem;
      }

			li.list-inline-item a.nav-link {
				padding: 0;

				[class*="fa fa-"] {
		      background-color: $brand-orange;
		      border-radius: 35px;
		      color: $white;
		      display: inline-block;
		      height: 35px;
		      line-height: 35px;
		      margin: auto 3px;
		      width: 35px;
		      font-size: 20px;
		      text-align: center;

		      &:hover {
		        background-color: $brand-red;
		      }
		    }
		  }
	  }
	}

	.footer-text {
		padding-top: 45px;
		p {
			color: $white;
			font-family: 'Arial';

			span.large-font {
				font-family: 'Cabin-Bold';
				font-size: 2em;
			}
		}
	}

	.copyright {
		p {
			color: $white;

			a {
				color: $white;

				&:hover {
					color: $brand-yellow;
				}
			}
		}
	}
}