header.banner {
  nav.navbar {
    background-color: $brand-orange;
    height: 45px;
    padding: 0;

    @include media-breakpoint-up(lg) {
      overflow: visible;
      height: auto;
    }

    button.navbar-toggler {
      padding: 10px;
      z-index: 100;
    }

    .navbar-collapse {
      background-color: $brand-orange;

      .mobile-padding {
        padding-top: 130px;

        @include media-breakpoint-up(md) {
          padding-top: 50px;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 0;
        }
      }

      .super-header {
        background-color: $brand-red;

        ul.social-links {
          margin-bottom: 0;
        }
      }
    }

    a.navbar-brand {
      img {
        background-color: $brand-navy;
        display: block;
        height: 120px;
        left: 30px;
        padding: 15px; 
        position: absolute;
        top: 0px;
        width: auto;
        z-index: 120;

        @include media-breakpoint-up(lg) {
          height: 100px;
        }

        @include media-breakpoint-up(xl) {
          height: 150px;
        }
      }
    }

    ul.social-links {
      margin-bottom: 0;
      padding: 15px;
      text-align: center;

      @include media-breakpoint-up(lg) {
        padding: 10px 10px 0 10px;
        text-align: right;
      }

      li.social-intro {
        color: $white;
        font-size: 1rem;
      }

      li {
        margin: 0;

        a.nav-link {
          padding: 0;

          [class*="fa fa-"] {
            background-color: $brand-navy;
            border-radius: 35px;
            color: $white;
            display: inline-block;
            height: 35px;
            line-height: 35px;
            margin: auto 3px;
            width: 35px;
            font-size: 20px;
            text-align: center;

            &:hover {
              background-color: $brand-red;
            }
          }
        }
      }
    }

    ul.navbar-nav {
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: right;
      }

      li {
        @extend .nav-item;

        a {
          @extend .nav-link;

          color: $white;
          font-size: 17px;
          padding: 15px !important;

          @include media-breakpoint-up(lg) {
            font-size: 15px;
            padding: 13px !important;
          }

          @include media-breakpoint-up(xl) {
            font-size: 17px;
            padding: 15px !important;
          }
        }

        &.active,
        &:hover {
          a {
            background-color: $brand-red;
            color: $white;
          }
        }

        .sub-menu {
          display: none;
          list-style: none outside none;
          font-size: 0.8em;
          position: absolute;
          padding-left: 0px;
          left: 0;
          z-index: 11000;

          > li {
            display: block;
            padding: 0px 10px;

            a {
              display: block;
              padding: 2px;
              text-decoration: none !important;
              text-align: center;
            }
          }

          > li:first-child {
            padding-top: 15px;
          }
          
          > li:last-child {
            padding-bottom: 15px;
          }
        }
      }
    }

    .dropdown-menu {
      background-color: $brand-red;
      left: -50%;
      text-align:center;

    }
  }
}