.inclusion-form input[type="submit"] {
  color: $white;
  background-color: $brand-primary;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 8px 25px;
  border-color: $brand-primary;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:hover {
    background-color: #218838;
  }
}