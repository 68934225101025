#wpadminbar {
  bottom: 0;
  top: inherit;
}
#wpadminbar .menupop .ab-sub-wrapper, #wpadminbar .shortlink-input {
  bottom: 32px;
}

/*==================================================
=            Page Customization            =
==================================================*/
h2,
h3,
h4 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

article,
.home-content {
  letter-spacing: 0.5px;

  ul li {
    padding: 3px 0;
  }
}

/*==================================================
=            Progress Bar Customization            =
==================================================*/
.progress-container {
	position: relative;
}
.bulb {
  background: $brand-yellow;
  border-radius: 100px;
  border: 2px solid $brand-orange;
  display: block;
  height: 40px;
  padding: 5px;
  position: absolute;
  top: -8px;
  left: -5px;
  width: 40px;
  .red-circle {
    background: $brand-red;
    border-radius: 100px;
    display: block;
    height: 30px;
    width: 30px;
    top: 3px;
    left: 3px;
    position: absolute;
  }
  .filler-red {
    background: $brand-red;
    border-radius: 0;
    display: block;
    height: 16px;
    width: 30px;
    position: relative;
    top: 6px;
    left: 25px;
    z-index: 30;
  }
  .filler-yellow {
    background: $brand-yellow;
	  border-top: 2px solid $brand-orange;
	  border-bottom: 2px solid $brand-orange;
    display: block;
    height: 26px;
    width: 10px;
    position: relative;
    top: -15px;
    left: 27px;
    z-index: 0;
  }
}

/*======================================
=            ACF Google Map            =
======================================*/
.acf-map {
  width: 100%;
  height: 400px;
  border: #ccc solid 1px;
  margin: 20px 0;
}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}


/*=====  End of ACF Google Map  ======*/

// ==========================================================================
// Social Share
// ==========================================================================
.share-links-container {
  ul.social-links {
    margin-bottom: 0;
    padding-right: 15px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding: 10px 10px 0 0;
      text-align: right;
    }

    li.social-intro {
      color: $white;
      font-size: 1rem;
    }

    li {
      margin: 0;

      a.blog-share-link {
        padding: 0;

        [class*="fa fa-"] {
          background-color: $brand-red;
          border-radius: 35px;
          color: $white;
          display: inline-block;
          height: 35px;
          line-height: 35px;
          margin: auto 3px;
          width: 35px;
          font-size: 20px;
          text-align: center;

          &:hover {
            background-color: $brand-orange;
          }
        }
      }
    }
  }
}
