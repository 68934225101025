// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:   #27ae60;
$brand-orange:		#f6921e;
$brand-red:				#d2232a;
$brand-navy:			#25408f;
$brand-yellow:		#ffcb05;
$white:					  #fff;
$light-gray:			#cccccc;
$gray-lighter:    #eceeef;


@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial.eot');
  src: url('../fonts/ArialMT.woff') format('woff'),
       url('../fonts/ArialMT.ttf') format('truetype'),
       url('../fonts/ArialMT.svg#ArialMT') format('svg'),
       url('../fonts/Arial.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin-Bold';
  src: url('../fonts/Cabin-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin-SemiBold';
  src: url('../fonts/Cabin-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin-Medium';
  src: url('../fonts/Cabin-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin-Regular';
  src: url('../fonts/Cabin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}