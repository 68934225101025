// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

/* WordPress Gallery
------------------------------------------ */
.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & > * { min-width: 0; }
}

.gallery-item.gallery-item {
  margin-bottom: 5px;
  text-align: center;

  a {
    display: block;
  }

  .widget-area & {
    margin-bottom: 5px;
  }
}

.gallery-icon img {
  margin: 0 auto;
  display: block;
}

@for $i from 1 through 9 {
  .gallery-columns-#{$i} {
    .gallery-item {
      flex-basis: calc(#{percentage(1/$i)} - 5px);
    }
  }
}
