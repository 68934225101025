.archive {
  .post-archive-content {
    padding-bottom: 20px;
  }

  .blog-divide {
    border: 1px solid $brand-navy;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
}

.post-archive-content {
  padding: 30px 0;

  img {
    height: auto;
    max-width: 100%;
  }
}

.post-content-1 {
  padding-top: 0;
}

#post-single {
  margin-top: 30%;

  @include media-breakpoint-up(md) {
    margin-top: 20%;
  }

  @include media-breakpoint-up(lg) { 
    margin-top: 15%;
  }

  header h1.entry-title {
    padding-bottom: 35px;
  }

  .single-blog-img {
    padding-bottom: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .entry-content {
    h2 {
      color: $brand-navy;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .single-blog-sidebar {
    h4 {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }

    .latest-posts {
      border-bottom: 1px solid $brand-navy;
      padding-bottom: 25px;
      padding-top: 35px;
    }

    .post0 {
      padding-top: 10px;
    }
  }
}

/* PLAYGROUND LAYOUT
   ========================================================================== */
#playground-archive-template {
  .background {
    .banner-text {
      h1 {
        color: $white;
        text-shadow: 2px 2px 8px #000;
      }

      p {
        color: $white;
        text-shadow: 2px 2px 8px #000;
      }
    }
  }

  .playground-tax {
    border-top: 1px solid $light-gray;
    padding-bottom: 30px
  }

  .playground-tax-1 {
    border: none;
  }

  .playground-content {
    padding-bottom: 40px;

    .category-content {
      .col-sm-6 {
        p.location {
          margin-bottom: 0px;
        }

        p.summary {
          font-size: 12px;
        }
      }
    }
  }

  .playground-header {
    padding-bottom: 25px;
    font-family: 'Cabin-SemiBold';
    color: $brand-red;
  }

  .playground-post {
    border: 1px solid $light-gray;
    padding: 15px;

    .progress {
      background-color: $brand-yellow;
      border: 2px solid $brand-orange;
      padding: 3px;

      .progress-bar {
        background-color: $brand-red;
      }
    }

    .content {
      a {
        img {
          height: auto;
          width: 100%;
        }
      }

      .col-lg-6 {
        position: relative;

        @include media-breakpoint-down(sm) {
          padding: 20px;
        }
      }

      .playground-name-info {
        a:hover {
          text-decoration: none;
        }

        h4 {
          color: $brand-red;
          padding: 0;
        }

        h4:hover {
          text-decoration: none;
        }

        p.location {
          color: $brand-red;
        }

        p.summary {
          color: $brand-navy;
        }
      }
    }

    .btn {
      @include media-breakpoint-up(lg) {
        font-size: 13px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 14px;
      }
    }
  }
}

/* SINGLE-PLAYGROUND.PHP
   ========================================================================== */
#single-playground {
  .background {
    position: relative;

    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);

      h1 {
        color: $white;
        text-shadow: 2px 2px 8px #000;
      }

      h3.location {
        color: $white;
        text-shadow: 2px 2px 8px #000;
      }
    }
  }

  .playground-text {
    padding: 40px 0px;

    .donate {
      padding: 0px 15px;

      h4 {
        padding: 0;
      }

      .progress {
        background-color: $brand-yellow;
        border: 2px solid $brand-orange;
        padding: 3px;

        .progress-bar {
          background-color: $brand-red;
        }
      }
    }

    #collapseForm {
      padding-top: 30px;
    }

    .location {
      padding-top: 15px;
    }

    .equipment {
      padding-top: 15px;
    }

    .in-progress-content {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .completed-pics {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
